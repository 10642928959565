import React, { useState, useEffect } from 'react';

import { Outlet } from "react-router-dom";

import {
  InfoCircleOutlined,
  UserAddOutlined,
  TeamOutlined,
  TableOutlined,
  ToolOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  ReloadOutlined,
  QrcodeOutlined,
  SaveOutlined,
  ExportOutlined
} from '@ant-design/icons';
import { Layout, Menu, FloatButton, Space, ConfigProvider } from 'antd';
import AddStudentPopup from '../addStudentPopup';
import UpdateURL, { EncodeURLEnding } from '../../functions/URLHandler';
import ExampleData from '../../functions/exampleData';


// React Redux
import store from '../../store.js';
import { Provider} from 'react-redux';
import ShareModal from '../sharemodal';
import { Footer, Header } from 'antd/es/layout/layout';
import { updateAllStudentsData } from '../../features/students/students';
import { updateAllKlassenraumData } from '../../features/klassenraum/klassenraum';

// Router
import router from '../../router/index';

import { Routes } from '../../router/routes';



const { Sider, Content } = Layout;


// Always updates URL to Store Information's
store.subscribe(() => {
    // console.log("Store has updated", store.getState(), "updating URL now...");
    try {
      UpdateURL(store.getState());
    } catch (error) {
      console.log(error.message);
    }
  })





export default function Root() {


    const [collapsed, setCollapsed] = useState(false);
  const [openMenu, setOpenMenu] = useState('1');
  const [OpenAddUserMenu, setOpenAddUserMenu] = useState(false);
  // Main Data Saver
  const [UserData, setUserData] = useState(ExampleData);

  // Modal for Sharing
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [linkQRCode, setLinkQRCode] = useState('https://vonreyher.com/kontakt');


    
  
    // get USER Data on first Load
    useEffect(() => {
        getURLdata();
      }, [])
    
      // const dispatch = useDispatch();
    
    
      // get USER Data after Reload of Page
      async function getURLdata() {
        const urlParams = await window.location.search;
        const newObject = await EncodeURLEnding(urlParams.substring(1))
        // Hier müssen alle Daten aus der URL Dispatched werden.
        // 1) Dispatch USER DATA
        store.dispatch(updateAllStudentsData(newObject.students))
        // 2) Dispatch Klassenraum DATA
        store.dispatch(updateAllKlassenraumData(newObject.klassenraum))
      }
    
    
    
      // Funktion to open the Popup to add a new User
      function handleNewStudent() {
        setOpenAddUserMenu(true)
      }
      
    
      // Funktion, wenn das Menu gedrückt wird
      function handleMenuClick(e) {
        var parameter = window.location.search.slice(1);
        let newURL = e.key + '?' + parameter
        router.navigate(newURL);
      }
  
  



  return (
    <>
          <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b96b',
          // fontFamily: 'Alexandria',
        },
      }}
    >
    <Provider store={store}>
      <Layout style={{height: '100vh'}}>
        
        <Header style={{ background: 'rgb(255 255 255)',border:  "solid 1px #f0f0f0", padding: 0, textAlign: 'center', height: '8vh', alignItems: 'center', display: 'flex', justifyContent:'center', padding: '10px'}}>
          <div style={{fontSize:"20px", fontWeight: "600", color:"#00B96B", letterSpacing: "1px" }}>sitzplan.digital</div>
        </Header>

        <Layout className="site-layout" style={{height: '88vh'}}>

          <Sider theme="light" breakpoint="lg" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <Menu
              theme="light"
              mode="inline"
              onClick={(e) => {handleMenuClick(e)}}
              defaultSelectedKeys={['/']}
              items={[
                {
                  key: '/',
                  icon: <HomeOutlined />,
                  label: 'Home',
                },
                {
                  key: Routes.SchulerView,
                  icon: <TeamOutlined />,
                  label: 'Schüler hinzufügen',
                },
                {
                  key: Routes.SitzplanView,
                  icon: <TableOutlined />,
                  label: 'Sitzplan',
                },
                {
                  key: Routes.ZufallsgeneratorView,
                  label: 'Zufallsgenerator',
                  icon: <ReloadOutlined />,
                },
                {
                  key: Routes.GruppenErstellerView,
                      label: 'Gruppen erstellen',
                      icon: <TeamOutlined />,
                },
                {
                  key: Routes.ExportierenView,
                      label: 'Als PDF Exportieren',
                      icon: <ExportOutlined />,
                },
                {
                  key: Routes.AboutView,
                  icon: <InfoCircleOutlined />,
                  label: 'Infos & Datenschutz',
                },
              ]}
            />
          </Sider>

          <Content className="site-layout-background">
            <div style={{width: "100%", display: "flex"}}>
              <div style={{width: "10%"}}></div>
              <div style={{width: "80%"}}  >
                <Outlet />
              </div>
              <div style={{width: "10%"}}></div>
            </div>
          </Content>

            
        </Layout>

        <Footer style={{
    padding: '2px',
        borderTop: '1px solid rgb(245 244 244)',
        width: '100%',
        backgroundColor: 'white',
        textAlign: 'center', color: "rgb(181 181 181)", height: '4vh'}}>
              Version: 1.0.2
        </Footer>
      </Layout>
      <FloatButton.Group
            shape="square"
          >
            <FloatButton icon={<UserAddOutlined />} tooltip={<div>Schüler hinzufügen</div>} onClick={handleNewStudent}/>
            <FloatButton icon={<SaveOutlined />} tooltip={<div>Link zum speichern erstellen</div>} onClick={(e) => {setLinkQRCode(window.location.href); setShareModalOpen(true)}}/>
            <FloatButton icon={<QrcodeOutlined />} tooltip={<div>QR-Code erstellen</div>} onClick={(e) => {setLinkQRCode(window.location.href); setShareModalOpen(true);}}/>
      </FloatButton.Group>
      <AddStudentPopup open={OpenAddUserMenu} userData={UserData} PopupOpen={setOpenAddUserMenu} updateAllData={setUserData}/>
      <ShareModal linkQRCode={linkQRCode} shareModalOpen={shareModalOpen} setShareModalOpen={setShareModalOpen}/>
    </Provider>
    </ConfigProvider>
    </>
  )}




  
  

  
